import React from "react";
import Table from "react-bootstrap/Table";
import Header from "./Header";
import Button from "react-bootstrap/esm/Button";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

export default function ClientsManagement() {
  const [mangeClients, setManageClients] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchname, setSearchName] = useState("");
  const [form, setForm] = useState({});
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState(clients);

  const handleSelected = (index) => {
    setSelected(index);
  };

  const handleClients = () => {
    setManageClients(false);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchName(searchValue);
    const filteredClients = clients.filter((client) => {
      const clientInfo = Object.values(client).join(" ").toLowerCase();
      return clientInfo.includes(searchValue);
    });

    setFilteredClients(filteredClients);
  };

  const handelInputData = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleClientsAdd = (e) => {
    e.preventDefault();
    setClients((prevClients) => [...prevClients, form]);
    setForm({});
    setFilteredClients((prevFilteredClients) => [...prevFilteredClients, form]); // Update filtered clients as well
  };

  return (
    <>
      <Header />

      <div className="row  m-auto ">
        <div className="row m-auto">
          {!mangeClients ? (
            <>
              <div className="col-md-12 m-auto ">
                <Button
                  className="col-md-3 mt-3"
                  style={{ marginLeft: "5px" }}
                  onClick={() => setManageClients(true)}
                >
                  Manage Clients
                </Button>
              </div>
              <div className="row containerPadding m-auto">
                <h5>Enter Client Details</h5>
                <Form className="card containerPadding ">
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Clients Name</Form.Label>
                      <Form.Control
                        name="clientname"
                        required
                        onChange={handelInputData}
                        value={form.clientname}
                        type="text"
                        placeholder="First name"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Client's Business Name</Form.Label>
                      <Form.Control
                        name="businessname"
                        required
                        onChange={handelInputData}
                        value={form.businessname}
                        type="text"
                        placeholder="Please Enter Business"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Client's Contact Number1</Form.Label>
                      <Form.Control
                        name="number1"
                        required
                        onChange={handelInputData}
                        value={form.number1}
                        type="text"
                        placeholder="Please Enter Number"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Client's Contact Number2</Form.Label>
                      <Form.Control
                        name="number2"
                        required
                        onChange={handelInputData}
                        value={form.number2}
                        type="text"
                        placeholder="Please Enter Number"
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Client's Email</Form.Label>
                      <Form.Control
                        name="email"
                        required
                        onChange={handelInputData}
                        value={form.email}
                        type="text"
                        placeholder="Please Enter email"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Number of Stores</Form.Label>
                      <Form.Control
                        name="stores"
                        required
                        onChange={handelInputData}
                        value={form.stores}
                        type="text"
                        placeholder="Enter number of stores"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        name="pincode"
                        required
                        onChange={handelInputData}
                        value={form.pincode}
                        type="text"
                        placeholder="Enter pincode"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Zone</Form.Label>
                      <Form.Control
                        name="zone"
                        value={form.zone}
                        required
                        onChange={handelInputData}
                        type="text"
                        placeholder="Enter  Zone"
                      />
                    </Form.Group>
                  </Row>

                  <Button
                    onClick={handleClientsAdd}
                    type="submit"
                    className="col-md-3 mt-4"
                  >
                    Add Client
                  </Button>
                </Form>
              </div>
              <div className="table-container containerPadding">
                <div className="table-wrapper">
                  <Table bordered className="col-md-12 ">
                    <thead>
                      <tr className="text-center">
                        <th colSpan={6}>
                          <span className="me-2">Clients Management</span>
                          <input
                            name="search"
                            value={searchname}
                            onChange={handleSearch}
                            placeholder="Search for business,name"
                          />
                        </th>
                      </tr>
                      <tr className="text-center">
                        <th>SI NO.</th>
                        <th>Client Name</th>
                        <th>Client Number</th>
                        <th>Business Name</th>
                        <th>Clients Email</th>
                        <th>No of Stores</th>
                        <th>Pincode</th>
                        <th>Zone</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tbody className="text-center">
                        {filteredClients.map((client, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{client.clientname}</td>
                            <td>{client.number1}</td>
                            <td>{client.businessname}</td>
                            <td>{client.email}</td>
                            <td>{client.stores}</td>
                            <td>{client.pincode}</td>
                            <td>{client.zone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          ) : !selected ? (
            <>
              <div className="containerPadding mt-3 ">
                <div className="col-md-1">
                  <ArrowCircleLeftIcon
                    className="col-md-6"
                    onClick={() => setManageClients(false)}
                    style={{ color: "#068FFF" }}
                  />{" "}
                </div>
              </div>
              <div className="table-container mt-4 containerPadding">
                <div className="table-wrapper">
                  <Table bordered className="col-md-12 ">
                    <thead>
                      <tr className="text-center">
                        <th colSpan={6}>Clients Management</th>
                      </tr>
                      <tr className="text-center">
                        <th>Client </th>
                        <th>Location</th>
                        <th>Number of Stores</th>
                        <th>Approval Status</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "green" }}
                            class="fa-solid fa-check"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "green" }}
                            class="fa-solid fa-check"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "green" }}
                            class="fa-solid fa-check"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "green" }}
                            class="fa-solid fa-check"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>

                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "red" }}
                            class="fa-solid fa-xmark"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "green" }}
                            class="fa-solid fa-check"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>Ramesh</td>
                        <td>R.R.Nagar</td>
                        <td>05</td>
                        <td>
                          <i
                            style={{ color: "red" }}
                            class="fa-solid fa-xmark"
                          ></i>
                        </td>
                        <td onClick={handleSelected}>
                          view <i class="fa-sharp fa-solid fa-angles-right"></i>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="containerPadding mt-3 ">
                <div className="col-md-1">
                  <ArrowCircleLeftIcon
                    className="col-md-6"
                    onClick={() => setSelected(null)}
                    style={{ color: "#068FFF" }}
                  />{" "}
                </div>
              </div>
              <div className="containerPadding mt-3 card">
                <div className="row m-auto">
                  <div className="col-md-6 m-auto">
                    <img
                      variant="top"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR34R0O05LegURUY4r7XpTJdl-LL6aM2oqFiT2ftXDI1IGF5v7-fseCri-eJ6hJqD-N2G0&usqp=CAU"
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "100%",
                      }}
                    />
                    <p>
                      {" "}
                      <span className="clr">Name:</span> Sohel
                    </p>
                    <p>
                      {" "}
                      <span className="clr">Itsy Bisty:</span> SMR2012{" "}
                    </p>
                    <p>
                      {" "}
                      <span className="clr">Email: </span>Sohel234@gmail.com
                    </p>
                    <p>
                      {" "}
                      <span className="clr">Account Number:</span>
                      21238475923432
                    </p>
                    <p>
                      {" "}
                      <span className="clr">Address:</span> #374, Dwaraka Nagar,
                      Channasandra Near Yuva Hardware
                    </p>
                    <div> </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
