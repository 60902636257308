import Header from "./Header";
import Button from "react-bootstrap/esm/Button";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

export default function Marketing() {
  const data = [
    {
      id: 1,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 2,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 3,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 4,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 5,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },

    {
      id: 6,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 7,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 8,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },

    {
      id: 9,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 10,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 11,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
    {
      id: 12,
      ClientsName: "Ramesh",
      BusinessName: "Ashapura Hardware",
      Stores: "Ashapura Hardware",
      StoreLocation: "Channasandra",
      zone: "West",
      pincode: "342564",
      Salesinfo: "Amit",
      date: "08/03/2023",
    },
  ];
  const columns = [
    {
      name: "SI.NO.",
      selector: "id",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },
    {
      name: "Name",
      selector: "ClientsName",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },
    {
      name: "Store Name",
      selector: "BusinessName",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },

    {
      name: "Vender Name",
      selector: "Stores",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },
    {
      name: "Store Location",
      selector: "StoreLocation",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },

      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      name: "Zone",
      selector: "zone",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },

      cellClassName: "custom-cell",
      headerClassName: "custom-header",
    },
    {
      name: "Pincode",
      selector: "pincode",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },
    {
      name: "Date",
      selector: "Salesinfo",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },

    {
      name: "Status",
      selector: "date",
      sortable: true,
      style: {
        borderRight: "1px solid black",
      },
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const customStyles = {
    table: {
      border: "none",
      width: "100%",
    },
    cells: {
      style: {
        borderLeft: "1px solid black",
        padding: "8px",
      },
    },
    headCells: {
      style: {
        border: "1px solid black",
        fontWeight: "bold",
        borderCollapse: "collapse",
        padding: "8px",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid black",
        wordBreak: "break-word",
        borderCollapse: "collapse",
      },
    },
  };

  return (
    <>
      <Header />

      <>
        {" "}
        <div className="row  m-auto containerPadding">
          <div className="col-md-12 ">
            <Button
              className="col-md-2"
              href="/MarketingAddClient"
              style={{ marginRight: "5px" }}
            >
              Add Clients
            </Button>

            <Button
              className="col-md-3 "
              href="/MarketingInfo"
              style={{ marginLeft: "5px" }}
            >
              Clients Information
            </Button>
          </div>
        </div>
        <div className="row  m-auto containerPadding">
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
              customStyles={customStyles}
            />
          </DataTableExtensions>
        </div>{" "}
      </>
    </>
  );
}
