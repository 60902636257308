import Header from "./Header";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";

function MarketingAddClient() {
  return (
    <>
      <Header />
      <div className="row containerPadding m-auto">
        <h5>Enter Client Details</h5>
        <Form className="card containerPadding ">
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Clients Name</Form.Label>
              <Form.Control
                name="clientname"
                required
                type="text"
                placeholder="First name"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Client's Business Name</Form.Label>
              <Form.Control
                name="businessname"
                required
                type="text"
                placeholder="Please Enter Business"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Client's Contact Number1</Form.Label>
              <Form.Control
                name="number1"
                required
                type="text"
                placeholder="Please Enter Number"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Client's Contact Number2</Form.Label>
              <Form.Control
                name="number2"
                required
                type="text"
                placeholder="Please Enter Number"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Client's Email</Form.Label>
              <Form.Control
                name="email"
                required
                type="text"
                placeholder="Please Enter email"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Number of Stores</Form.Label>
              <Form.Control
                name="stores"
                required
                type="text"
                placeholder="Enter number of stores"
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                name="pincode"
                required
                type="text"
                placeholder="Enter pincode"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Zone</Form.Label>
              <Form.Control
                name="zone"
                required
                type="text"
                placeholder="Enter  Zone"
              />
            </Form.Group>
          </Row>
          <Row className="mt-5" style={{ border: "1px solid grey" }}></Row>{" "}
          <p className="text-center">Sales Team Information</p>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Sales Represantive</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter sales represantive name"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Date of Client addition</Form.Label>
              <Form.Control required type="date" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Button className="col-md-2 m-auto" href="/Marketing">
              Add Clients
            </Button>{" "}
          </Row>
        </Form>
      </div>
    </>
  );
}

export default MarketingAddClient;
