import Header from "./Header";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import BorderColorIcon from "@mui/icons-material/BorderColor";
export default function Setting() {
  return (
    <>
      <Header />

      <div className="row  m-auto containerPadding">
        <div className="row m-auto ">
          <div className="col-md-2 m-auto">
            <div className="row">
              <div>
                <AccountCircleIcon
                  style={{ fontSize: "100px", color: "grey" }}
                />
                <CameraAltIcon
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "61%",
                    color: "grey",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row m-auto ">
          <div className="col-md-2 m-auto ">
            <div className="row">
              <div>
                <h6>
                  {" "}
                  Sri magic prints
                  <BorderColorIcon />{" "}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
